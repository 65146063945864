// import code for every page
import '@/components/cookieConsent';
import { loadSvg } from '@/core/utils';
//import '@/helpers/freshchat';
import '@/helpers/eventLog';

// load svg sprite
document.addEventListener('DOMContentLoaded', () => {
  loadSvg(abApp.svgSpriteUrl);
});


// expand mobile nav accordion
document.querySelectorAll('.js-mobile-menu-expand').forEach((button) => {
  button.addEventListener('click', () => {
    button.classList.toggle('active');
    const items = button.nextElementSibling;

    items.style.maxHeight = button.classList.contains('active')
      ? `1500px`
      : '0px';
  });
});

const timestampsToLocal = document.querySelectorAll('.js-timestamp-to-local');

timestampsToLocal.forEach((el) => {
  // Get the timestamp from the data attribute and parse it as an integer
  const timestamp = new Date(parseInt(el.dataset.timestamp) * 1000);

  const timezoneOffset = new Date().getTimezoneOffset() * 60;

  // Calculate the local date by subtracting the offset from the UTC date
  const localDate = new Date(timestamp.getTime() + timezoneOffset);

  // Extract hours, minutes, and AM/PM format
  let hours = localDate.getHours();
  const minutes = localDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12; // Convert to 12-hour format
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero to minutes

  // Format the date as a string (e.g., 02:30 PM)
  const formattedTime = `${hours}:${minutesFormatted} ${ampm}`;

  // Set the formatted local time as the element's innerText
  el.innerText = formattedTime;
});
