export const fsGoogle = new (function () {
    this.process = (key) =>
        new Promise((resolve) => grecaptcha.ready(() => resolve()))
            .then(() => grecaptcha.execute(key, { action: 'submit' }))
            .then((token) =>
                smFunc.getJson('/app/google/recaptcha/', 'POST', { token: token })
            );
})();

export const smFunc = new (function () {
    this.getJson = (url, method, data) =>
        fetch(url, {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            body: JSON.stringify(data),
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error();
            }
        });

    this.getMyTime = () => {
        const now = new Date();
        this.getJson(
            `/app/application/getMyTime/?time=${now.getTimezoneOffset() * 60}`,
            'GET'
        );
    };
})();

export function getRecaptchaToken(key) {
    return new Promise((resolve) => grecaptcha.ready(() => resolve())).then(() =>
        grecaptcha.execute(key, { action: 'submit' })
    );
}

export function processEmailCapture(email, source, customAP = false) {
    smFunc.getJson('/app/cta/process/?do=emailcap', 'POST', {
        email,
        source,
        customAP,
    });
}

/*
export function isUserLoggedIn() {
    return parseInt(abApp.userId) > 0;
}
*/